import Heading from "./Heading"

type Props = {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const SubHeading = ({ style, children }: Props) => {
  return (
    <Heading level={2} style={{ color: '#3C414D', ...style }}>
      { children }
    </Heading>
  )
}

export default SubHeading
