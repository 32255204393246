import styled from "styled-components"
import { Lang, PaymentInput, Progress } from "../../helpers/enums"
import { reload } from "../../helpers/popupWindow"
import { media } from "../../helpers/styles"
import useApp from "../../hooks/useApp"
import Dropdown from "../Build/Dropdown"
import SecuredBy from "../Build/SecuredBy"
import ExternalSecuredBy from "../Build/ExternalSecuredBy"
import { IPaymentFormConfiguration } from "../../services/link.transform"

const options = [
  {
    text: 'English',
    value: Lang.English,
  },
  {
    text: 'ไทย',
    value: Lang.Thai,
  },
  {
    text: '日本語',
    value: Lang.Japanese,
  },
]

type Props = {
  showSecuredBy?: boolean;
  showExternalSecuredBy?: boolean;
  paymentFormConfiguration?: IPaymentFormConfiguration;
}

const Footer = ({ showSecuredBy = false, showExternalSecuredBy= false, paymentFormConfiguration }: Props) => {
  const { state, setLang } = useApp()

  const handleSwitchLanguage = (value: string) => {
    setLang(value)
    if(state.progress === Progress.Checkout && state.paymentInput === PaymentInput.FillForm) {
      reload(window.location)
    }
  }

  return (
    <FooterWrapper>
      <FooterContainer progress={state.progress}>
        { showSecuredBy &&
          <SecuredByWrapper>
            <SecuredBy/>
          </SecuredByWrapper>
        }
        { showExternalSecuredBy &&
          <SecuredByWrapper>
            <ExternalSecuredBy paymentFormConfiguration={paymentFormConfiguration}/>
          </SecuredByWrapper>
        }
        <DropdownWrapper>
          <Dropdown
            value={state.lang}
            options={options}
            onChange={handleSwitchLanguage}
          />
        </DropdownWrapper>
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer

type FooterContainerProps = {
  progress: Progress;
}

export const SecuredByWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  position: absolute;
  top: -12px;
  right: 0;
`

export const FooterContainer = styled.div.attrs((props: FooterContainerProps) => ({
  progress: props.progress,
}))`
  display: flex;
  position: relative;
  width: ${(props) => {
    if([Progress.Paid, Progress.CallbackAuth, Progress.PaymentFail].includes(props.progress)) {
      return '376px'
    } else if([Progress.Checkout, Progress.Error].includes(props.progress)) {
      return '700px'
    } else {
      return '100%'
    }
  }};
  margin: auto;
`

const FooterWrapper = styled.footer`
  margin-top: 24px;

  @media ${media.mobile} {
      margin: 0;

      ${FooterContainer} {
        width: 100%;
        padding: 0 15px 30px 15px;
        /* margin-top: -50px; */
        box-sizing: border-box;

        ${DropdownWrapper} {
          right: 10px;
        }
      }
  }
`
