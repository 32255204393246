import styled from 'styled-components';

const Main = styled.div`
  position: relative;
  width: 700px;
  height: 574px;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(4, 7, 13, 0.1);
  border-radius: 4px;
  overflow: hidden;
`

export default Main
