export enum Progress {
  Checkout,
  Paid,
  CallbackAuth,
  PaymentFail,
  Error,
}

export enum Lang {
  English = 'en',
  Thai = 'th',
  Japanese = 'ja'
}

export enum SocialPlatform {
  Email,
  Line,
  Facebook,
  Instagram,
}

export enum ErrorResponse {
  LinkExpiredDate,
  InternalServer,
  PaymentFail,
  PaymentCancel,
  SentEmailFail,
  Unknown,
  None,
}

export enum LinkStatus {
  Active = 'active',
  Pending = 'pending',
  Paid = 'paid',
  Expired = 'expired',
}

export enum OmiseChargeStatus {
  Failed = 'failed',
  Expired = 'expired',
  Pending = 'pending',
  Reversed = 'reversed',
  Successful = 'successful',
}

export enum PaymentMethod {
  CreditCard = 'credit_card',
}

export enum PaymentType {
  Token,
  Source,
  WLB,
  Unknown,
}

export enum PaymentInput {
  FillForm,
  ScanQrCode,
}

export enum CallbackUriPath {
  Auth = 'callback_auth',
}
