import { useEffect, useRef, useState } from "react";
import useApp from "../hooks/useApp";
import { loadScript, transformConfig } from "../helpers/utils";
import { ErrorResponse } from "../../../helpers/enums";
import Container from "./Container";
import Loading from "./Loading";
import Main from "./Main";
import Merchant from "./Merchant";
import MerchantBody from "./MerchantBody";
import MerchantFooter from "./MerchantFooter";
import Payment from "./Payment";
import Payjs from "./Payjs";

const App = (props: Props) => {
  const {
    config,
    omisejsCdn,
    customForm: customFormComponent,
    error,
    onClickRefundPolicy,
    onSubmit,
    onReady,
  } = props;
  const { state } = useApp({ config });
  const payjsRef = useRef<HTMLDivElement>(null);
  const [isLoadingPayjs, setIsLoadingPayjs] = useState<boolean>(true);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [omisejsLoaded, setOmisejsLoaded] = useState<boolean>(false);
  const [omisejsConfigured, setOmisejsConfigured] = useState<boolean>(false);
  const [OmiseCard, setOmiseCard] = useState<OmiseCard>({
    configure: () => ({}),
    open: () => ({}),
  });

  const callbackSuccess = (payload: OmiseCallbackPayload) => {
    if (onSubmit !== undefined) {
      onSubmit(payload);
    }
  }

  const getOptions = (omisejsConfig: Config): OpenOptions => {

    return {
      amount: omisejsConfig?.amount,
      currency: omisejsConfig?.currency,
      defaultPaymentMethod: omisejsConfig?.defaultPaymentMethod,
      onCreateSuccess: callbackSuccess,
      showEmailField: omisejsConfig?.saveCard,
      saveCardRequired: omisejsConfig?.saveCard,
      responseSettings: {
        token: ['id', 'email'],
        source: ['id'],
      },
    };
  };

  useEffect(() => {
    if (!isInit) {
      setIsInit(true);
      loadScript(omisejsCdn || "https://cdn.omise.co/omise.js")
        .then(() => {
          const omiseCard: OmiseCard = window.OmiseCard;
          setOmiseCard(omiseCard);
          setOmisejsLoaded(true);
        })
        .catch((err) => {
          console.error("Something went wrong!", err);
        });
    }
  }, [OmiseCard, isInit, omisejsCdn]);

  useEffect(() => {
    if (omisejsLoaded && !omisejsConfigured) {
      if (!!customFormComponent) {
        setIsLoadingPayjs(false);
        setOmisejsConfigured(true);
        if (onReady) {
          onReady();
        }
      } else if (omisejsLoaded && !omisejsConfigured) {
        setOmisejsConfigured(true);
        const omisejsConfig = transformConfig(config);
        const options = getOptions(omisejsConfig);
        OmiseCard.configure(omisejsConfig);
        OmiseCard.open(options);
        const iframe = document.querySelector(
          "#omise-checkout-iframe-app"
        ) as HTMLIFrameElement;
        if (payjsRef.current && iframe) {
          payjsRef.current.appendChild(iframe);
          iframe.addEventListener("load", () => {
            setIsLoadingPayjs(false);
            if (onReady) {
              onReady();
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omisejsLoaded, OmiseCard, omisejsConfigured, config, onSubmit, onReady]);

  // watch payment error response
  useEffect(() => {
    // if payment is failed or canceled, open omise.js again
    if (
      [ErrorResponse.PaymentFail, ErrorResponse.PaymentCancel].includes(error)
    ) {
      const omisejsConfig = transformConfig(config);
      const options = getOptions(omisejsConfig);
      OmiseCard.open(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Container fontFamily={state.style.fontFamily}>
      <Main>
        <Merchant backgroundColor={state.style.backgroundColor}>
          <MerchantBody
            currency={state.order.currency}
            order={state.order}
            fontColor={state.style.fontColor}
            showRefund={state.refundPolicy ? true : false}
            onClickRefundPolicy={onClickRefundPolicy}
          />
          <MerchantFooter
            fontColor={state.style.fontColor}
            expireDate={state.order.expireDate}
          />
        </Merchant>
        <Payment>
          {isLoadingPayjs && <Loading />}
          {customFormComponent ? customFormComponent : null}
          <Payjs
            ref={payjsRef}
            style={{ display: customFormComponent ? "none" : "" }}
          />
        </Payment>
      </Main>
    </Container>
  );
};

declare global {
  interface Window {
    OmiseCard: OmiseCard;
  }
}

type Config = any;

type OpenOptions = any;

interface OmiseCard {
  configure: (config: Config) => void;
  open: (options: OpenOptions) => void;
}

export interface OmiseCallbackPayload {
  token: string | { id: string; email?: string } | undefined;
  source: string | { id: string; } | undefined;
}

type Props = {
  omisejsCdn?: string;
  config: Config;
  customForm: React.ReactNode;
  error: ErrorResponse;
  onClickRefundPolicy?: () => void;
  onSubmit?: (response: any) => void;
  onReady?: () => void;
};

export default App;
