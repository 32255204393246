import * as htmlToImage from 'html-to-image'
import { jsPDF } from "jspdf"

export const domToImage = (nodeElement: HTMLDivElement) : any => {
  return new Promise((resolve, reject) => {
    if(nodeElement) {
      htmlToImage.toJpeg(nodeElement, { skipFonts: true })
        .then((base64: string) => {
          resolve(base64)
        })
        .catch((error: any) => {
          reject(error)
        })
    }
  })
}

export const saveAsImage = (nodeElement: HTMLDivElement) : any => {
  return new Promise((resolve, reject) => {
    domToImage(nodeElement)
      .then((base64: string) => {
        const link = document.createElement('a')
        const fileName = `${new Date().toLocaleString()}.jpeg`
        document.body.appendChild(link)
        link.setAttribute('href', base64)
        link.setAttribute('download', fileName)
        link.click()
        link.remove()
        resolve([null, fileName])
      })
      .catch((error: any) => {
        reject([error, null])
      })
  })
}

export const saveAsPdf = (nodeElement: HTMLDivElement) : any => {
  return new Promise((resolve, reject) => {
    domToImage(nodeElement)
      .then((base64: string) => {
        const doc = new jsPDF()
        const [x, y] = [0, 0]
        const [A4_WIDTH_IN_MM, A4_HEIGTH_AUTO] = [210, 0]
        const fileName = `${new Date().toLocaleString()}.pdf`
        doc.addImage(base64, 'jpeg', x, y, A4_WIDTH_IN_MM, A4_HEIGTH_AUTO)
        doc.save(fileName)
        resolve([null, fileName])
      })
      .catch((error: any) => {
        reject([error, null])
      })
  })
}
