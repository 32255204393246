export const setItem = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value)
  } catch(e) {
    console.error(e)
  }
}

export const getItem = (key: string): string => {
  try {
    const data = localStorage.getItem(key)
    if (data) return data
    return ''
  } catch (e) {
    console.error(e)
    return ''
  }
}

export const removeItem = (key: string): void => {
  try {
    localStorage.removeItem(key)
  } catch(e) {
    console.error(e)
  }
}
