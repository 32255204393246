import { Fragment, useContext } from 'react'
import { IntlProvider } from 'react-intl'
import { AppContext } from '../contexts'
import messages from './messages'

type Props = {
  children: React.ReactNode;
}

export const I18nProvider = ({ children }: Props) => {
  const { state } = useContext(AppContext)
  return (
    <IntlProvider
      locale={state.lang}
      textComponent={Fragment}
      messages={{ ...messages[state.lang] }}
    >
      { children }
    </IntlProvider>
  )
}
