import styled from "styled-components";
import { media } from "../../helpers/styles";
import Loading from "../Build/Loading";

type Props = {
  children?: React.ReactNode;
  isLoading: any;
}

const Layout = ({ children, isLoading }: Props) => {
  return (
    <>
      { isLoading &&
        <Loading/>
      }
      <Wrapper isLoading={isLoading}>
        { children }
      </Wrapper>
    </>
  )
}

export default Layout

const Wrapper = styled.div.attrs((props: Props) => ({
  isLoading: props.isLoading,
}))`
  margin: 64px 0 0 0;
  opacity: ${(props) => props.isLoading ? '0' : '1'};
  transition: opacity ease-in-out 500ms;

  @media ${media.mobile} {
    margin: 0;
  }
`
