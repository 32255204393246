import { useEffect, useState } from "react";
import { getLang } from "../helpers/locale";
import {
  getInstallmentTerms,
  getPaymentMethodCodes,
} from "../helpers/paymentMethod";
import {
  IResponseApiPostCharge,
  IResponseApiGetCharge,
  transformPostCharge,
  transformGetCharge,
} from "../services/charges.transform";
import { IResponseApiLink, transformLink } from "../services/link.transform";

const useMerchant = () => {
  const [responseApiLink, setResponseApiLink] = useState<IResponseApiLink>(
    transformLink({})
  );
  const [responseApiPostCharge, setResponseApiPostChage] =
    useState<IResponseApiPostCharge>(transformPostCharge({}));
  const [responseApiGetCharge, setResponseApiGetChage] =
    useState<IResponseApiGetCharge>(transformGetCharge({}));
  const [omiseConfig, setOmiseConfig] = useState<IOmiseConfig>({
    ...initialConfig,
  });
  const [isConfigured, setIsConfigured] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    if (
      responseApiLink.merchant.publicKey &&
      !omiseConfig.publicKey &&
      !isConfigured
    ) {
      setIsConfigured(true);
      setOmiseConfig({
        ...omiseConfig,
        publicKey: responseApiLink.merchant.publicKey,
        image: responseApiLink.merchant.logo,
        frameLabel: responseApiLink.merchant.name,
        frameDescription: responseApiLink.merchant.description,
        locale: getLang(),
        currency: responseApiLink.order.currency,
        amount: responseApiLink.order.amount,
        otherPaymentMethods: [
          ...getPaymentMethodCodes(responseApiLink.capabilities),
        ],
        installmentTerms: [
          ...getInstallmentTerms(responseApiLink.capabilities),
        ],
        zeroInterestinstallments: (() => {
          if (responseApiLink.zeroInterestinstallments === null) {
            return undefined;
          }
          return responseApiLink.zeroInterestinstallments;
        })(),
        style: {
          ...omiseConfig.style,
          orderSelection: {
            backgroundColor: responseApiLink.merchant.theme.backgroundColor,
            fontColor: responseApiLink.merchant.theme.fontColor,
          },
          submitButton: {
            backgroundColor: responseApiLink.merchant.theme.backgroundColor,
            textColor: responseApiLink.merchant.theme.fontColor,
          },
          link: {
            textColor: responseApiLink.merchant.theme.backgroundColor,
          },
        },
        additionalDetail: {
          title: responseApiLink.name,
          meta: responseApiLink.order.meta,
          expireDate: responseApiLink.order.expiryDate,
        },
        refundPolicy: responseApiLink.merchant.policy.refund,
        saveCard: responseApiLink.saveCard,
      });
    }
  }, [responseApiLink, omiseConfig, isConfigured]);

  return {
    id,
    omiseConfig,
    setId,
    responseApiPostCharge,
    responseApiGetCharge,
    responseApiLink,
    setResponseApiLink,
    setResponseApiPostChage,
    setResponseApiGetChage,
  };
};

export type TInstallmentTerm = {
  name: string;
  terms: number[];
};

export type IOmiseConfig = {
  publicKey: string;
  image: string;
  frameLabel: string;
  frameDescription: string;
  locale: string;
  currency: string;
  amount: number;
  otherPaymentMethods: string[];
  installmentTerms: TInstallmentTerm[];
  zeroInterestinstallments: boolean | undefined | null;
  style: {
    fontFamily: string;
    defaultSelectPaymentMethods: boolean;
    alwaysFullScreen: boolean;
    securedBySection: {
      position: string;
      left: string;
      bottom: string;
    };
    body: {
      width: string;
      padding: {
        desktop: string;
        mobile: string;
      };
    };
    orderSelection: {
      backgroundColor: string;
      fontColor: string;
    };
    methodsListSection: {
      maxHeight: string;
      scrollY: boolean;
    };
    closeButton: {
      visible: boolean;
    };
    submitButton: {
      backgroundColor: string;
      textColor: string;
    };
    link: {
      textColor: string;
    };
    logo: {
      width: string;
      height: string;
    };
  };
  additionalDetail: {
    title: string;
    meta: {
      name: string;
      value: string;
    }[];
    expireDate: string;
  };
  refundPolicy: string;
  saveCard: boolean;
};

export const defaultConfig = {
  style: {
    fontFamily: "Poppins, Arial, sans-serif",
    defaultSelectPaymentMethods: true,
    alwaysFullScreen: true,
    body: {
      width: "100%",
      padding: {
        desktop: "24px",
        mobile: "24px",
      },
    },
    methodsListSection: {
      maxHeight: "306px",
      scrollY: true,
    },
    closeButton: {
      visible: false,
    },
    logo: {
      width: "52px",
      height: "52px",
    },
  },
};

export const initialConfig: IOmiseConfig = {
  ...defaultConfig,
  publicKey: "",
  image: "",
  frameLabel: "",
  frameDescription: "",
  locale: "en",
  currency: "thb",
  amount: 0,
  otherPaymentMethods: [],
  installmentTerms: [],
  zeroInterestinstallments: null,
  style: {
    ...defaultConfig.style,
    securedBySection: {
      position: "",
      left: "",
      bottom: "",
    },
    orderSelection: {
      backgroundColor: "",
      fontColor: "",
    },
    submitButton: {
      backgroundColor: "",
      textColor: "",
    },
    link: {
      textColor: "",
    },
  },
  additionalDetail: {
    title: "",
    meta: [],
    expireDate: "",
  },
  refundPolicy: "",
  saveCard: false,
};

export default useMerchant;
