import styled from 'styled-components'
import { media } from '../../../helpers/styles'
import Main from './Main'
import Merchant from './Merchant'
import { PurchaseDetail, MerchantBodyList } from './MerchantBody'
import { MerchantFooterWrapper, MerchantFooterLine } from './MerchantFooter'
import Payment from './Payment'
import Payjs from './Payjs'

type ContainerProps = {
  fontFamily: string;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  fontFamily: props.fontFamily || 'inherit',
}))`
  font-family: ${(props) => props.fontFamily};

  #omise-checkout-iframe-app {
    position: absolute !important;
    background: none !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    transition: none !important;
  }

  @media ${media.mobile} {
    ${Main} {
      position: relative;
      width: 100%;
      height: auto;
      left: 0;
      transform: none;
      background: none;
      box-shadow: none;
      border-radius: 0;
    }

    ${Merchant} {
      position: relative;
      width: 100%;
      height: auto;
      top: auto;
      left: auto;
      padding-bottom: 50px;
    }

    ${Payment} {
      position: relative;
      top: -50px;
      left: 50%;
      width: calc(100% - 32px);
      height: 574px;
      transform: translateX(calc(-50%));
    }

    ${Payjs} {
      width: 100%;
    }

    ${PurchaseDetail} {
      margin: 0 0 30px 0;
    }

    ${MerchantFooterWrapper} {
      position: relative;
      padding: 24px 0;
      margin-top: 30px;
    }

    ${MerchantBodyList} {
      max-height: inherit;
      overflow: hidden;
    }

    ${MerchantFooterLine} {
      width: 100%;
    }

    #omise-checkout-iframe-app {
      left: 50% !important;
      transform: translateX(calc(-50%)) !important;
      box-shadow: 0 2px 10px rgba(4, 7, 13, .1) !important;
      border-radius: 10px !important;
    }
}
`

export default Container
