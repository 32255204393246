import {  useState, useEffect } from "react"
import { OmisePaymentComponent } from "../../plugins/omisejs-wrapped"
import { IOmiseConfig } from "../../hooks/useLink"
import { ErrorResponse } from "../../helpers/enums"
import { OmiseCallbackPayload } from "../../plugins/omisejs-wrapped/components/App"

type Props = {
  omiseConfig: IOmiseConfig;
  customForm: React.ReactNode;
  error: ErrorResponse;
  onReady?: () => void;
  onClickRefundPolicy?: () => void;
  onSubmit?: (payload: OmiseCallbackPayload) => void;
}

const Payment = ({ omiseConfig, customForm, error, onReady, onClickRefundPolicy, onSubmit }: Props) => {
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    const hasConfig = omiseConfig.publicKey.length
    if(hasConfig) {
      setIsReady(true)
    }
  }, [omiseConfig])

  return isReady ?
    <OmisePaymentComponent
      omisejsCdn={process.env.REACT_APP_OMISEJS_URL}
      config={omiseConfig}
      customForm={customForm}
      error={error}
      onReady={onReady}
      onClickRefundPolicy={onClickRefundPolicy}
      onSubmit={onSubmit}
    /> : null
}

export default Payment
