import styled from "styled-components";

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 8px 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid #E4E7ED;
  outline: none;
`

export default Input
