import { OmiseChargeStatus } from "../helpers/enums"
import { defaultsValue } from "../helpers/utils"
import { transformLinkFeatures } from "./link.transform"

export const transformGetCharge = (data : any = {}) : IResponseApiGetCharge => {
  return {
    id: defaultsValue(data?.id) as string,
    status: defaultsValue(data?.status) as OmiseChargeStatus,
    currency: defaultsValue(data?.currency) as string,
    amount: defaultsValue(data?.amount) as number,
    paymentDate: defaultsValue(data?.paid_at) as string,
    source: transformSource(data?.source),
    card: transformCard(data?.card),
    additional: defaultsValue(data?.metadata?.link_name) as string,
    metadata: transformLinkFeatures(defaultsValue(data?.metadata, {}) as object),
    failureCode: defaultsValue(data?.failure_code) as PaymentFailureCode,
    failureMessage: defaultsValue(data?.failure_message) as string,
  }
}

export const transformSource = (data : any = {}) : TSource => {
  return {
    type: defaultsValue(data?.type) as string,
  }
}

export const transformCard = (data : any = {}) : TCard => {
  return {
    brand: defaultsValue(data?.brand) as string,
    lastDigits: defaultsValue(data?.last_digits) as string,
  }
}

export const transformPostCharge = (data : any = {}) : IResponseApiPostCharge => {
  return {
    id: defaultsValue(data?.id) as string,
    lastDigit: defaultsValue(data?.last_digit) as string,
    paymentDate: defaultsValue(data?.payment_date) as string,
    paymentMethod: defaultsValue(data?.payment_method) as string,
    brand: defaultsValue(data?.card_brand) as string,
    authorizeUri: defaultsValue(data?.authorize_uri) as string,
    qrCodeUri: defaultsValue(data?.qr_code_uri) as string,
  }
}

export interface IResponseApiGetCharge {
  id: string;
  status: OmiseChargeStatus;
  currency: string;
  amount: number;
  paymentDate: string;
  source: TSource;
  card: TCard;
  additional: string;
  metadata: {
    name: string;
    value: string;
  }[];
  failureCode: PaymentFailureCode;
  failureMessage: string;
}

type TSource = {
  type: string;
}

type TCard = {
  brand: string;
  lastDigits: string;
}

enum PaymentFailureCode {
  confirmed_amount_mismatch = "confirmed_amount_mismatch",
  failed_fraud_check = "failed_fraud_check",
  failed_processing = "failed_processing",
  insufficient_balance = "insufficient_balance",
  insufficient_fund = "insufficient_fund",
  invalid_account_number = "invalid_account_number",
  invalid_account = "invalid_account",
  invalid_security_code = "invalid_security_code",
  payment_cancelled = "payment_cancelled",
  payment_rejected = "payment_rejected",
  stolen_or_lost_card = "stolen_or_lost_card",
  timeout = "timeout",
}

export interface IResponseApiPostCharge {
  id: string;
  lastDigit: string;
  paymentDate: string;
  paymentMethod: string;
  brand: string;
  authorizeUri: string;
  qrCodeUri: string;
}
