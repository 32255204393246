import styled from "styled-components"
import Button from "../Build/Button"

type Props = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const Action = ({ className, style, children }: Props) => {
  return (
    <WrapperAction className={className} style={style}>
      { children }
    </WrapperAction>
  )
}

export const WrapperAction = styled.div`
  display: flex;
  justify-content: center;

  ${Button} {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

export default Action
