import { useState } from "react";
import styled from "styled-components";
import LocaleIcon from "./LocaleIcon"

const Dropdown = ({ placeholder, value, options, onChange }: TDropdownProps) => {
  const [toggle, setToggle] = useState<boolean>(false)
  const selected : string | null = (() => {
    const item = options.find((_item) => _item.value === value) || null
    return item
      ? item.text
      : null
  })()
  const onSelectedItem = (val: TValue) => {
    if(onChange) {
      onChange(val)
    }
    setToggle(!toggle)
  }
  return (
    <Wrapper
      active={toggle}
    >
      <TranslationIcon>
        <LocaleIcon />
      </TranslationIcon>
      <WrapperSelected>
        <List>
          <ItemSelected
            onClick={(e) => setToggle(!toggle)}
            data-testid="select-item-button"
          >
            { selected ? selected :
              <Placeholder>
                { placeholder || "Select an item" }
              </Placeholder>
            }
            <Arrow active={toggle} />
          </ItemSelected>
        </List>
      </WrapperSelected>
      { toggle &&
        <WrapperOptions>
          <ListOptions>
            { options.map((item, index) => {
              return (
                <ItemOptions
                  key={index}
                  onClick={(e) => onSelectedItem(item.value)}
                  data-testid="dropdown-item"
                >
                  { item.text }
                </ItemOptions>
              )
            }) }
          </ListOptions>
        </WrapperOptions>
      }
    </Wrapper>
  )
}

export default Dropdown

type TValue = string | number | null

type TOptions = {
  text: string;
  value: TValue;
}

type TWrapperProps = {
  active: boolean;
}

type TDropdownProps = {
  placeholder?: string;
  value: TValue;
  options: TOptions[];
  onChange?(value: TValue): void;
}

const WrapperSelected = styled.div``

const WrapperOptions = styled.div`
  position: absolute;
  width: 100%;
  top: calc(100% - 111px);
  left: 0;
  box-sizing: border-box;
`

const Item = styled.li`
  position: relative;
  padding: 11px 24px 11px 11px;
  text-align: left;
  font-size: 12px;
  color: #B6BCCA;
  cursor: pointer;
  user-select: none;
  list-style: none;
`

const Arrow = styled.i.attrs((props: TWrapperProps) => ({
  active: props.active || false,
}))`
  position: absolute;
  top: ${props => props.active ? '35%' : '45%'};
  right: 8px;
  border: solid #B6BCCA;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: ${props => props.active ? 'rotate(45deg)' : 'rotate(-135deg)'};
  -webkit-transform: ${props => props.active ? 'rotate(45deg)' : 'rotate(-135deg)'};
`

const ItemSelected = styled(Item)``

const List = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListOptions = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px #5B626F;
  border-radius: 4px;
`

const ItemOptions = styled.li`
  position: relative;
  padding: 11px 18px;
  text-align: left;
  font-size: 12px;
  color: #5B626F;
  cursor: pointer;
  user-select: none;
  list-style: none;
  
  &:hover {
    background: #F7F8FA;
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
`

const Placeholder = styled.span`
  color: #ccc;
`

const Wrapper = styled.div.attrs((props: TWrapperProps) => ({
  active: props.active || false,
}))`
  display: flex;
  position: relative;
  z-index: 1;
`

const TranslationIcon = styled.span`
  position: relative;
  padding-top: 9px;
  color: #B6BCCA;
`