import styled from "styled-components"
import { media } from "../../helpers/styles"

const Container = styled.div`
  width: 376px;
  margin: auto;

  @media ${media.mobile} {
    margin: 15px auto 30px auto;
    padding: 0 15px;
    box-sizing: border-box;
  }
`

export default Container
