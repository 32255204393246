import { useEffect, useState } from "react";
import { getPayAmount } from "../helpers/utils";

const useApp = ({ config }: Props) => {
  const [state, setState] = useState<TInitialState>(getInitialState(config));

  useEffect(() => {
    setState(getInitialState(config));
  }, [config]);

  return {
    state,
  };
};

export const getInitialState = (config: IOmiseConfig): TInitialState => {
  return {
    order: {
      amount: getPayAmount(
        config.amount,
        config.currency?.trim().toUpperCase()
      ),
      currency: config.currency?.trim().toUpperCase(),
      additional: {
        title: config.additionalDetail?.title,
        meta: config.additionalDetail?.meta,
      },
      expireDate: config.additionalDetail?.expireDate,
    },
    style: {
      backgroundColor: config.style?.orderSelection?.backgroundColor,
      fontColor: config.style?.orderSelection?.fontColor,
      fontFamily: config.style?.fontFamily,
    },
    refundPolicy: config.refundPolicy,
  };
};

export type Props = {
  config: IOmiseConfig;
};

export type TInitialState = {
  order: TOrder;
  style: TStyle;
  refundPolicy: string;
};

export type TOrder = {
  amount: string;
  currency: string;
  additional: {
    title: string;
    meta: {
      name: string;
      value: string;
    }[];
  };
  expireDate: string;
};

export type TStyle = {
  backgroundColor: string;
  fontColor: string;
  fontFamily: string;
};

export type IOmiseConfig = {
  publicKey: string;
  image: string;
  frameLabel: string;
  frameDescription: string;
  locale: string;
  currency: string;
  amount: number;
  otherPaymentMethods: string[];
  style: {
    fontFamily: string;
    defaultSelectPaymentMethods: boolean;
    body: {
      width: string;
      padding: {
        desktop: string;
        mobile: string;
      };
    };
    orderSelection: {
      backgroundColor: string;
      fontColor: string;
    };
    methodsListSection: {
      maxHeight: string;
      scrollY: boolean;
    };
    closeButton: {
      visible: boolean;
    };
    submitButton: {
      backgroundColor: string;
      textColor: string;
    };
    link: {
      textColor: string;
    };
  };
  additionalDetail: {
    title: string;
    meta: {
      name: string;
      value: string;
    }[];
    expireDate: string;
  };
  refundPolicy: string;
};

export default useApp;
