import styled from "styled-components"
import Icon, { IconList } from "./Icon"

const SecuredBy = () => {
  return (
    <SecuredByWrapper>
      <Text>
        Secured by
      </Text>
      <OpnIcon>
        <Icon
          src={IconList.Opn}
          style={{ height: '12px', }}
        />
      </OpnIcon>
    </SecuredByWrapper>
  )
}

export default SecuredBy

const SecuredByWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Text = styled.span`
  margin-right: 8px;
  font-size: 10px;
  color: #ABB2C2;
`

const OpnIcon = styled.span`
  position: relative;
  top: -4px;
`
