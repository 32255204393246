import { FormattedMessage, useIntl as intlHook } from "react-intl";

/**
 * short-handed by using `translate` function instead of `FormattedMessage` component,
 * see: https://formatjs.io/docs/react-intl/components/#formattedmessage
 */
export const translate = (id: string) => {
  return <FormattedMessage id={id} />;
};

export enum message {
  charge_id = "charge_id",
  purchase_details = "purchase_details",
  see_refund_policy = "see_refund_policy",
  here = "here",
  pay_before = "pay_before",
  payment_successful = "payment_successful",
  payment_date = "payment_date",
  payment_method = "payment_method",
  back_to_merchant = "back_to_merchant",
  save_to_files = "save_to_files",
  share_to_email = "share_to_email",
  mail_receipt = "mail_receipt",
  enter_email_send_receipt = "enter_email_send_receipt",
  email = "email",
  cancel = "cancel",
  send = "send",
  receipt_sent = "receipt_sent",
  oops_link_is_invalid = "oops_link_is_invalid",
  link_has_been_used_or_doesnt_exist = "link_has_been_used_or_doesnt_exist",
  unable_to_process_the_payment = "unable_to_process_the_payment",
  unable_to_send_email = "unable_to_send_email",
  done = "done",
  payment_has_been_finished = "payment_has_been_finished",
  please_follow_instructions_payment = "please_follow_instructions_payment",
  qr_code_desc_desktop_no_1 = "qr_code_desc_desktop_no_1",
  qr_code_desc_desktop_no_2 = "qr_code_desc_desktop_no_2",
  qr_code_desc_desktop_no_3 = "qr_code_desc_desktop_no_3",
  qr_code_desc_desktop_no_4 = "qr_code_desc_desktop_no_4",
  qr_code_desc_mobile_no_1 = "qr_code_desc_mobile_no_1",
  qr_code_desc_mobile_no_2 = "qr_code_desc_mobile_no_2",
  qr_code_desc_mobile_no_3 = "qr_code_desc_mobile_no_3",
  qr_code_desc_mobile_no_4 = "qr_code_desc_mobile_no_4",
  qr_code_desc_mobile_no_5 = "qr_code_desc_mobile_no_5",
  qr_code_desc_mobile_no_6 = "qr_code_desc_mobile_no_6",
  qr_code_please_notes = "qr_code_please_notes",
  qr_code_please_notes_no_1 = "qr_code_please_notes_no_1",
  qr_code_please_notes_no_2 = "qr_code_please_notes_no_2",
  download_qr_code = "download_qr_code",
  payment_failed = "failed",
}

export const useIntl = intlHook;
