import { datadogRum } from '@datadog/browser-rum'
import packageJson from '../../package.json'
// ref: https://docs.datadoghq.com/real_user_monitoring/browser/#example
interface IDataDogRumConfig {
  applicationId: string,
  clientToken: string,
  site?: string,
  sampleRate?: number,
  silentMultipleInit?: boolean,
  trackInteractions?: boolean,
  service?: string,
  env?: string,
  version?: string,
  allowedTracingOrigins?: string[],
  trackSessionAcrossSubdomains?: boolean,
  useSecureSessionCookie?: boolean,
  useCrossSiteSessionCookie?: boolean,
  replaySampleRate: number,
}

const config : IDataDogRumConfig = {
  applicationId: process.env.REACT_APP_DD_APPLICATION_ID as string,
  clientToken: process.env.REACT_APP_DD_CLIENT as string,
  env: process.env.REACT_APP_DD_ENV as string,
  trackInteractions: true,
  version: packageJson.version,
  replaySampleRate: 0,
}

export const init = () => {
  datadogRum.init(config)
  datadogRum.startView(window.location.pathname)
}
