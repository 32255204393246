import { createContext, useReducer } from "react"
import { PaymentInput, Progress } from "../helpers/enums";
import { reducer, initialState } from "./reducer"

export type InitialState = {
  isLoading: boolean;
  lang: string;
  progress: Progress;
  paymentInput: PaymentInput;
}

const AppContext = createContext<{
  state: InitialState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
});

type Props = {
  children: React.ReactNode;
}

const AppProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
    	{ children }
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
