import { useRef, useState } from "react"
import styled from "styled-components"
import { message, translate, useIntl } from "../../i18n/translate.helper"
import Button from "../Build/Button"
import Form from "../Build/Form"
import Heading from "../Build/Heading"
import Icon, { IconList } from "../Build/Icon"
import Input from "../Build/Input"
import Action, { WrapperAction  } from "./Action"

export type FormProps = {
  email: string;
}

type Props = {
  isSubmitted: boolean;
  onClickCancel?: () => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>, form: FormProps) => void;
}

const FormMail = ({ isSubmitted, onClickCancel, onSubmit }: Props) => {
  const intl = useIntl()
  const emailRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState<string>('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!email.trim().length && emailRef.current) {
      emailRef.current.focus()
    } else if(onSubmit) {
      const form: FormProps = { email }
      onSubmit(e, form)
    }
  }

  return (
    <Wrapper>
      <Heading level={4}>
        { translate(message.mail_receipt) }
      </Heading>
      <SubHeading>
        { translate(message.enter_email_send_receipt) }
      </SubHeading>
      <Form onSubmit={handleSubmit}>
        <Input
          ref={emailRef}
          type="email"
          placeholder={intl.formatMessage({ id: message.email })}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Action>
          <Button
            type="button"
            className="cancel-btn"
            disabled={isSubmitted}
            onClick={onClickCancel}
          >
            { translate(message.cancel) }
          </Button>
          <Button
            className="submit-btn"
            disabled={isSubmitted}
          >
            { isSubmitted ?
              <Icon
                className="loading"
                style={{ width: '20px', height: '20px' }}
                src={IconList.Loading}
              /> :
              translate(message.send)
            }
          </Button>
        </Action>
      </Form>
    </Wrapper>
  )
}

export default FormMail

const Wrapper = styled.div`
  ${Form} {
    margin-top: 48px;
  }

  ${WrapperAction} {
    justify-content: flex-end;
    margin-top: 48px;

    ${Button} {
      margin-right: 0;
      font-weight: normal;

      &:disabled {
        opacity: .5;
      }

      &.cancel-btn {
        background: none;
        color: #1A56F0;
      }

      &.submit-btn {
        background-color: #1A56F0;
      }
    }
  }
`

const SubHeading = styled.div`
  font-size: 14px;
`
