import styled from "styled-components"
import Cell from "../Build/Cell"

type Item = {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

type Props = {
  title?: string;
  items: Item[];
  style?: React.CSSProperties;
}

const Detail = ({ title, items, style }: Props) => {
  return (
    <Wrapper style={style}>
      { title &&
        <Title>{ title }</Title>
      }
      { items.map(({ label, value }, index) => (
          <Cell
            key={index}
            label={label}
            value={value}
          />
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`

`

const Title = styled.div`
  font-size: 14px;
  color: #3C414D;
  margin-bottom: 8px;
`

export default Detail
