import styled from "styled-components"

type Props = {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

const Cell = ({ label, value }: Props) => {
  return (
    <Wrapper data-testid="cell">
      <Label>{ label }</Label>
      <Value>{ value }</Value>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Label = styled.div`
  font-size: 12px;
  color: #3C414D;
  opacity: 0.7;
`

const Value = styled.div`
  font-size: 12px;
  color: #3C414D;
`

export default Cell
