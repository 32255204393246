import styled from "styled-components"
import { IPaymentFormConfiguration } from "../../services/link.transform";


type Props = {
  paymentFormConfiguration?: IPaymentFormConfiguration;
}

const ExternalSecuredBy = ({paymentFormConfiguration}: Props) => {
  return (
    <SecuredByWrapper>
      <Text>
        {paymentFormConfiguration?.textForFooter}
      </Text>
    </SecuredByWrapper>
  )
}

export default ExternalSecuredBy

const SecuredByWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Text = styled.span`
  margin-right: 8px;
  font-size: 14px;
  color: #ABB2C2;
`
