import { ErrorResponse } from "../../helpers/enums";
import { message, useIntl } from "../../i18n/translate.helper";
import ErrorMessage from "../Combine/ErrorMessage";
import Footer from "../Combine/Footer";
import { getPspFromURL } from "../../plugins/omisejs-wrapped/helpers/utils";
import { isExternalSecuredBy } from "../../helpers/utils"
import { IPaymentFormConfiguration } from "../../services/link.transform";

type Props = {
  type: ErrorResponse;
  paymentFormConfiguration: IPaymentFormConfiguration;
};

const InvalidLink = ({ type, paymentFormConfiguration }: Props) => {
  const intl = useIntl();
  return (
    <>
      <ErrorMessage
        type={type}
        message={intl.formatMessage({ id: message.oops_link_is_invalid })}
        description={intl.formatMessage({
          id: message.link_has_been_used_or_doesnt_exist,
        })}
      />
      <Footer
        showSecuredBy={!getPspFromURL()}
        showExternalSecuredBy={isExternalSecuredBy(paymentFormConfiguration)}
        paymentFormConfiguration={paymentFormConfiguration}
      />
    </>
  );
};

export default InvalidLink;
