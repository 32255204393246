import { v4 as uuidv4 } from 'uuid';
import httpClient from './client'
import ENDPOINT from './endpoint'
import { getEmailFromToken, getLinkId, getPaymentType, getRedirectUri, getTokenOrSourceId } from '../helpers/utils'
import { PaymentType } from '../helpers/enums'
import { OmiseCallbackPayload } from '../plugins/omisejs-wrapped/components/App';

export const getCharges = (id: string) => httpClient.get(`${ENDPOINT.LINKSPLUS}/${ENDPOINT.CHARGES}/${id}`)
export const postCharges = (data: TChargeBody) => httpClient.post(`${ENDPOINT.LINKSPLUS}/${ENDPOINT.CHARGES}`, data)
export const postEmailReceipt = (chargeId: string, data: TEmailReceiptBody) => httpClient.post(`${ENDPOINT.CHARGES}/${chargeId}/email/receipt`, data)

export type TChargeBody = {
  token?: string;
  source?: string;
  return_uri?: string;
  transaction_id: string;
  client_id: string;
  webhooks?: string[];
  email?: string | null;
}

export const getChargeBody = (value: OmiseCallbackPayload, webhooks: string[] ) : TChargeBody => {
  const paymentType = getPaymentType(value)
  const transactionId = getLinkId(window.location)
  const body = {
    transaction_id: transactionId,
    client_id: uuidv4(),
    webhooks,
  }
  if(paymentType === PaymentType.Token) {
    return {
      ...body,
      token: getTokenOrSourceId(value.token),
      return_uri: getRedirectUri(window.location, transactionId),
      email: getEmailFromToken(value.token),
    }
  } else if(paymentType === PaymentType.Source) {
    return {
      ...body,
      source: getTokenOrSourceId(value.source),
      return_uri: getRedirectUri(window.location, transactionId),
    }
  }  else if(paymentType === PaymentType.WLB) {
    return {
      ...body,
      token: getTokenOrSourceId(value.token),
      source: getTokenOrSourceId(value.source),
      return_uri: getRedirectUri(window.location, transactionId),
    }
  } else {
    return {
      ...body,
    }
  }
}

type TEmailReceiptBody = {
  email: string;
}

export const getEmailReceiptBody = (email: string): TEmailReceiptBody => {
  return {
    email,
  }
}
