import styled from 'styled-components';

const Payment = styled.div`
  position: relative;
  width: 400px;
  height: 574px;
  left: 300px;
`

export default Payment
