import { useEffect, useState } from "react"
import { AxiosError, AxiosResponse } from "axios"
import useApp from "../../hooks/useApp"
import useLink from "../../hooks/useLink"
import { getLink } from "../../services/link.api"
import { IPaymentFormConfiguration, transformLink } from "../../services/link.transform"
import { postCharges, postEmailReceipt, getCharges, getChargeBody, getEmailReceiptBody, TChargeBody } from "../../services/charges.api"
import { transformGetCharge, transformPostCharge } from "../../services/charges.transform"
import { STORAGE_CHARGE_ID, STORAGE_LANGUAGE } from "../../helpers/constants"
import { CallbackUriPath, ErrorResponse, LinkStatus, OmiseChargeStatus, PaymentInput, Progress } from "../../helpers/enums"
import { getItem, removeItem, setItem } from "../../helpers/storage"
import { getCallbackUriType, getLinkId, getPaylinkUri, redirect } from "../../helpers/utils"
import Paylink from "../Assemble/Paylink"
import { getPspFromURL } from "../../plugins/omisejs-wrapped/helpers/utils"
import { OmiseCallbackPayload } from "../../plugins/omisejs-wrapped/components/App"
import InvalidLink from "../Assemble/InvalidLink"

const Index = () => {
  const {
    isError,
    error,
    startLoading,
    stopLoading,
    progressTo,
    setPaymentInput,
    setLang,
    setError,
  } = useApp()
  
  const {
    omiseConfig,
    responseApiPostCharge,
    responseApiGetCharge,
    setId,
    setResponseApiLink,
    setResponseApiPostChage,
    setResponseApiGetChage,
    responseApiLink,
  } = useLink()

  const [returnUri, setReturnUri] = useState<string>('');
  const [paymentFormConfiguration, setPaymentFormConfiguration] = useState<IPaymentFormConfiguration>({
    textForFooter: '',
  });

  const callbackResponseError = (err: AxiosError) => {
    if((err.response?.status || -1) >= 500) {
      setError(ErrorResponse.InternalServer)
    } else {
      setError(ErrorResponse.Unknown)
    }
  }

  const callbackResponseGetLinkError = (err: AxiosError) => {
    if(err.response?.status === 404) {
      setError(ErrorResponse.Unknown)
    } else if(err.response?.status === 409) {
      setError(ErrorResponse.LinkExpiredDate)
    } else {
      callbackResponseError(err)
    }
    progressTo(Progress.Error)
    stopLoading()
  }

  const callbackResponseGetLinkSuccess = (response: AxiosResponse) => {
    const transformedData = transformLink(response.data)
    const isInvalidLink = [
      LinkStatus.Paid,
      LinkStatus.Expired,
    ].includes(transformedData.order.state as LinkStatus)
    const isInvalidPublicKey = !transformedData.merchant.publicKey
    // even response 200, but needs handle errors these cases
    if(isInvalidLink || isInvalidPublicKey) {
      setError(ErrorResponse.LinkExpiredDate)
      setPaymentInput(PaymentInput.FillForm)
      progressTo(Progress.Error)
      stopLoading()
    } else {
      // if user visit paylink first time, set default language as custom from merchant dashboard
      if(!getItem(STORAGE_LANGUAGE)) {
        setLang(transformedData.lang)
      }
      setId(transformedData.id)
      setResponseApiLink(transformedData)
    }
  }

  // send request to server every 1 secound for checking payment status
  const onCheckingChargeStatus = (chargeId: string) => {
    const loop = setInterval(() => {
      getCharges(chargeId)
        .then((response: AxiosResponse) => {
          const responseApi = transformGetCharge(response.data)
          if (response.data?.capturable) {
            clearInterval(loop);
            setError(ErrorResponse.None);
            setResponseApiGetChage(responseApi);
            progressTo(Progress.Paid);
            stopLoading();
          }
          if(responseApi.status === OmiseChargeStatus.Successful) {
            clearInterval(loop)
            setError(ErrorResponse.None)
            setResponseApiGetChage(responseApi)
            progressTo(Progress.Paid)
            stopLoading()
          } else if(responseApi.status === OmiseChargeStatus.Failed) {
            clearInterval(loop)
            setError(ErrorResponse.None)
            setResponseApiGetChage(responseApi)
            progressTo(Progress.PaymentFail)
            stopLoading()
          } else if(responseApi.status !== OmiseChargeStatus.Pending) {
            clearInterval(loop)
            setError(ErrorResponse.Unknown)
            stopLoading()
          }
        })
        .catch((err: AxiosError) => {
          const linkId = getLinkId(window.location)
          const linkUri = getPaylinkUri(window.location, linkId)
          redirect(linkUri, window.location)
        })
    }, 1000)
  }

  useEffect(() => {
    const callbackType = getCallbackUriType(window.location)
    if(callbackType === CallbackUriPath.Auth) {
      const chargeId = getItem(STORAGE_CHARGE_ID)
      const linkId = getLinkId(window.location);
      getLink(linkId)
        .then((response: AxiosResponse) => {
          const companyInfo = response?.data?.company;
          const linkReturnUrl = response?.data?.returnUrl;
          const merchantReturnUri = companyInfo?.return_url;
          const pspInfo = response?.data?.company?.pspInfo;
          updateFavicon(pspInfo);
          setReturnUri(linkReturnUrl || merchantReturnUri);
          setPaymentFormConfiguration({
            ...paymentFormConfiguration,
            textForFooter: pspInfo?.payment_form_configuration?.text_for_footer,
          });
        })
        .catch(callbackResponseGetLinkError)

      onCheckingChargeStatus(chargeId)
    } else {
      // clear "charge_id" from web browser every time
      // when payers making a new payment
      removeItem(STORAGE_CHARGE_ID)

      const id = getLinkId(window.location)
      getLink(id)
        .then((response: AxiosResponse) => {
          const pspInfo = response?.data?.company?.pspInfo;
          updateFavicon(pspInfo);
          callbackResponseGetLinkSuccess(response);
          setPaymentFormConfiguration({
            ...paymentFormConfiguration,
            textForFooter: pspInfo?.payment_form_configuration?.text_for_footer,
          });
        })
        .catch(callbackResponseGetLinkError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (payload: OmiseCallbackPayload) => {
    setError(ErrorResponse.None)
    startLoading()
    const merchantWebhooks = responseApiLink.merchant.webhooks;
    const data = getChargeBody(payload, merchantWebhooks)
    postCharges(data)
      .then((response: AxiosResponse) => {
        const responseApi = transformPostCharge(response.data)
        const isScanQrCode = responseApi.qrCodeUri
        const isRequiredAuth = responseApi.authorizeUri
        const chargeId = responseApi.id
        if(isScanQrCode) {
          setResponseApiPostChage(responseApi)
          setPaymentInput(PaymentInput.ScanQrCode)
          progressTo(Progress.Checkout)
          stopLoading()

          onCheckingChargeStatus(chargeId)
        } else if(isRequiredAuth) {
          // set "charge_id" on web browser, will be used when
          // payer redirect from 3rd service to see result on link+ app
          setItem(STORAGE_CHARGE_ID, responseApi.id)

          redirect(responseApi.authorizeUri, window.location)
        } else {
          setResponseApiPostChage(responseApi)
          progressTo(Progress.Paid)
          stopLoading()
        }
      })
      .catch(() => {
        setError(ErrorResponse.PaymentFail)
        stopLoading()
      })
  }

  const handleSendReceiptEmail = (email: string, callback: (error: any) => void) => {
    setError(ErrorResponse.None)
    const data = getEmailReceiptBody(email)
    postEmailReceipt(responseApiGetCharge.id, data)
      .then(() => {
        const err = null
        callback(err)
      })
      .catch((err: AxiosError) => {
        setError(ErrorResponse.SentEmailFail)
        callback(err)
      })
  }

  const handleCancelSendReceiptEmail = () => {
    setError(ErrorResponse.None)
  }

  const handleClickBackQrCode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    progressTo(Progress.Checkout)
    setPaymentInput(PaymentInput.FillForm)
    setError(ErrorResponse.PaymentCancel)
  }

  const handleClickBackToMerchantPage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    redirect(returnUri, window.location);
  }

  const updateFavicon = (pspInfo: { id?: string, favicon16: string, favicon32: string }) => {
    const subdomainPsp = getPspFromURL()
    if (!!subdomainPsp) {
      const { id, favicon16, favicon32 } = pspInfo;

      if (id && !id.includes('omise')) {
        let links: NodeListOf<HTMLLinkElement> = document.querySelectorAll("link[rel~='icon']");
        links[0].href = favicon16;
        links[1].href = favicon32;
      }
    }
  }

  return (
    <>
      { isError ?
        <InvalidLink
          type={error}
          paymentFormConfiguration={paymentFormConfiguration}
        /> :
        <Paylink
          omiseConfig={omiseConfig}
          responseApiPostCharge={responseApiPostCharge}
          responseApiGetCharge={responseApiGetCharge}
          error={error}
          onReady={() => stopLoading()}
          onSubmit={handleSubmit}
          onSendReceiptEmail={handleSendReceiptEmail}
          onCancelSendReceiptEmail={handleCancelSendReceiptEmail}
          onClickBackQrCode={handleClickBackQrCode}
          onClickBackToMerchantPage={returnUri ? handleClickBackToMerchantPage : null}
          paymentFormConfiguration={paymentFormConfiguration}
        />
      }
    </>
  )
}

export default Index
