import styled from "styled-components";
import Heading from "../Build/Heading";
import SubHeading from "../Build/SubHeading";
import Action from "./Action";
import Button from "../Build/Button";
import { message, translate } from "../../i18n/translate.helper";

type Props = {
  title: string;
  description: string;
  onClickBackToMerchantPage?:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | null;
};

const Callback = (props: Props) => {
  const onClickBackToMerchant = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (props.onClickBackToMerchantPage) {
      props.onClickBackToMerchantPage(e);
    }
  };

  return (
    <CallbackWrapper>
      <CallbackInner>
        <Heading>{props.title}</Heading>
        <SubHeading style={{ fontSize: "13px" }}>
          {props.description}
        </SubHeading>
        {props.onClickBackToMerchantPage && (
          <Action style={{ marginTop: "65px" }} className="action">
            <Button
              data-cy="btn_back-to-merchant"
              onClick={onClickBackToMerchant}
              style={{ backgroundColor: "#1500FF" }}
            >
              {translate(message.back_to_merchant)}
            </Button>
          </Action>
        )}
      </CallbackInner>
    </CallbackWrapper>
  );
};

export default Callback;

const CallbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(4, 7, 13, 0.1);
  box-sizing: border-box;
`;

const CallbackInner = styled.div`
  padding: 24px 24px 32px 24px;
  background: #fff;
  border-radius: 4px;
`;
