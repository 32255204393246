import styled, { css } from 'styled-components'

const LocaleIcon = styled.span`
  ${() => css`
    display: inline-block;
    height: 16px;
    padding-left: 4px;
    position: relative;
    width: 20px;

    &:before {
      content: 'A';
      display: block;
      font-family: 'Lato Regular', Poppins, Helvetica, Arial, sans-serif;
      font-size: 0.9rem;
      height: 1.2rem;
      position: absolute;
      transform: translate(-0.3rem, -0.1rem);
      width: 0.7rem;
    }
    &:after {
      content: 'あ';
      display: block;
      font-family: 'PingFang SC Regular', Poppins, Helvetica, Arial, sans-serif;
      font-size: 0.9rem;
      height: 1.2rem;
      position: absolute;
      transform: translate(0.4rem, 0.3rem);
      width: 1rem;
    }
  `}
`

export default LocaleIcon