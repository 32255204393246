import dayjs from "dayjs";
import numeral from "numeral";
import endpoint from "../services/endpoint";
import { apiUrl } from "../services/client";
import { CallbackUriPath, PaymentType } from "./enums";
import { mapPaymentMethod2FriendlyText } from "./paymentMethod";
import { OmiseCallbackPayload } from "../plugins/omisejs-wrapped/components/App";
import { IPaymentFormConfiguration } from "../services/link.transform";
import { getPspFromURL } from "../plugins/omisejs-wrapped/helpers/utils";

export const defaultsValue = (
  initialValue: any,
  defaultValue: any = ""
): any => {
  if (initialValue === null || initialValue === undefined) {
    return defaultValue;
  }
  return initialValue;
};

export const getLinkId = (location: Location): string => {
  try {
    return location.pathname.split("/")[1].toString();
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const getPayAmount = (value: number, currency: string) => {
  if (currency === "JPY") {
    return numeral(value).format("0,");
  }
  return numeral(value / 100).format("0,0.00");
};

/**
 * @param value example: tokn_test_5pgwoab75w57r0x3ww6, src_test_5pgwofh7yjq3c7qmkjn
 * @returns PaymentType
 */
export const getPaymentType = (value: OmiseCallbackPayload): PaymentType => {
  /**
   * if responseSettings is provided like this:
   *  responseSettings: {
        token: ['id', 'email'],
        source: ['id'],
      }
    This will be the callback payload:
     {
      "token": {
        "id": "tokn_test_1234",
        "email": "gaurav"
      },
      "source": {
        "id": "src_test_1234",
      }
    }
   */
  if (value.token && !value.source && typeof value.token === 'string' && /^tokn_/.test(value.token)) {
    return PaymentType.Token;
  } else if (value.token && !value.source && typeof value.token === 'object' && /^tokn_/.test(value.token.id)) {
    return PaymentType.Token;
  } else if (!value.token && value.source && typeof value.source === 'object' && /^src_/.test(value.source.id)) {
    return PaymentType.Source;
  } else if (value.token && value.source) {
    return PaymentType.WLB;
  }
  return PaymentType.Unknown;
};

export const getRedirectUri = (location: Location, id: string) => {
  return `${location.origin}/${getRedirectPath(id)}`;
};

export const getRedirectPath = (id: string) => {
  return `${id}/${CallbackUriPath.Auth}`;
};

export const getPaylinkUri = (location: Location, id: string) => {
  return `${location.origin.toString()}/${id}`;
};

export const redirect = (uri: string, location: Location) => {
  location.href = uri;
};

export const getCallbackUriType = (
  location: Location
): CallbackUriPath | null => {
  try {
    const path = location.pathname.split("/").slice(-1)[0];
    if (path === CallbackUriPath.Auth) {
      return CallbackUriPath.Auth;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

type TGetFriendlyPaymentMethod = {
  sourceType: string;
  cardBrand: string;
  cardLastDigits: string;
};

export const getFriendlyPaymentMethod = ({
  sourceType,
  cardBrand,
  cardLastDigits,
}: TGetFriendlyPaymentMethod) => {
  if (sourceType) {
    return `${mapPaymentMethod2FriendlyText(sourceType)}`;
  } else if (cardBrand) {
    return `${cardBrand} ****${cardLastDigits}`;
  }
  return "";
};

export const getFriendlyDate = (date: string) => {
  return dayjs(date).format("D MMM YYYY, HH:mm:ss");
};

export const getQrCodeImage = (imgUrl: string) => {
  return `${apiUrl}${endpoint.LINKSPLUS}/convert/svg-to-image?url=${imgUrl}`;
};

export const getTokenOrSourceId = (value: string | {id: string} | undefined) => {
  if ( value ) {
    return typeof value === 'string' ? value : value.id;
  }
}

export const getEmailFromToken = (token: string | {email?: string} | undefined) => {
  return typeof token === 'object' ? token.email : null;
}

export const isExternalSecuredBy = (paymentFormConfiguration: IPaymentFormConfiguration) => {
  return !!getPspFromURL() && !!paymentFormConfiguration?.textForFooter;
}
