import styled from "styled-components";
import { TOrder } from "../hooks/useApp";
import { message, translate } from "../../../i18n/translate.helper";

const MerchantBody = ({
  order,
  currency,
  fontColor,
  showRefund,
  onClickRefundPolicy,
}: Props) => {
  return (
    <Wrapper fontColor={fontColor}>
      <PurchaseDetail>
        <Title style={{ marginBottom: "8px" }}>
          {translate(message.purchase_details)}
        </Title>
        <Total>
          {currency} {order.amount}
        </Total>
      </PurchaseDetail>
      <Title style={{ marginBottom: "10px" }}>{order.additional.title}</Title>
      <MerchantBodyList>
        {order.additional.meta.map(({ name, value }, index) => (
          <Item key={index}>
            <Name>{name}</Name>
            <Value>{value}</Value>
          </Item>
        ))}
      </MerchantBodyList>
      {showRefund && (
        <RefundPolicy>
          {translate(message.see_refund_policy)}
          <Button fontColor="red" onClick={onClickRefundPolicy}>
            {translate(message.here)}
          </Button>
        </RefundPolicy>
      )}
    </Wrapper>
  );
};

type Props = {
  currency: string;
  order: TOrder;
  fontColor: string;
  showRefund: boolean;
  onClickRefundPolicy?: () => void;
};

type WrapperProps = {
  fontColor: string;
};

type ButtonProps = {
  fontColor: string;
};

const Wrapper = styled.div.attrs((props: WrapperProps) => ({
  fontColor: props.fontColor || "#000",
}))`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.fontColor};
`;

export const PurchaseDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin: 54px 0 30px 0;
`;

const Total = styled.strong`
  font-size: 24px;
`;

const Title = styled.strong`
  font-size: 14px;
  font-weight: 400;
`;

export const MerchantBodyList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 180px;
  overflow: auto;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Name = styled.span`
  margin-right: 30px;
  font-size: 12px;
  opacity: 0.7;
`;

const Value = styled.span`
  text-align: right;
  font-size: 12px;
`;

const RefundPolicy = styled.p`
  margin: 30px 0 0 0;
  font-size: 12px;
  opacity: 0.7;
`;

const Button = styled.button.attrs((props: ButtonProps) => ({
  fontColor: props.fontColor || "#000",
}))`
  background: none;
  border: none;
  outline: none;
  margin-left: 4px;
  padding: 0;
  font-family: inherit;
  text-decoration: underline;
  color: ${(props) => props.fontColor};
  cursor: pointer;
`;

export default MerchantBody;
