interface Container extends Pos {
  width: number;
  height: number;
}

type Options = {
  width: number;
  height: number;
}

type Pos = {
  x: number;
  y: number;
}

export const getWindowPos = (container: Container, options: Options) : Pos => {
  return {
    x: container.width / 2 + container.x - ( options.width / 2),
    y: container.height / 2 + container.y - ( options.height / 2),
  }
}

export const popupWindow = (url: string, windowName: string, newWindow: any, options: Options) => {
  const windowContainer : Container = {
    width: newWindow.top.innerWidth,
    height: newWindow.top.innerHeight,
    x: newWindow.top.screenX,
    y: newWindow.top.screenY,
  }
  const pos = getWindowPos(windowContainer, options)
  const config: any = {
    width: options.width,
    height: options.height,
    left: pos.x,
    top: pos.y,
  }
  const features = Object.keys(config).reduce((acc: string[], cur: any) => {
    const [key, value] = [cur, config[cur]]
    acc.push(`${key}=${value}`)
    return acc
  }, []).join(', ')
  return newWindow.open(url, windowName, features)
}

export const openEmail = (to: string, subject: string, message: string) => {
  const url = `
    mailto:${to}?
    subject=${subject}&
    body=${message}&
  `
  window.open(url)
}

export const openUrl = (url: string) => {
  window.open(url)
}

export const reload = (location: Location) => {
  location.reload()
}
