import en from './en'
import th from './th'
import ja from './ja'

type Messages = {
  [key: string]: object
}

const messages: Messages = {
  en,
  th,
  ja,
}

export default messages
