import styled from "styled-components"

type Props = {
  children: React.ReactNode;
}

const Card = ({ children }: Props) => {
  return (
    <CardWrapper>
      { children }
    </CardWrapper>
  )
}

export default Card

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardHead = styled.div``

export const CardBody = styled.div``

export const CardFooter = styled.div``
