import styled from "styled-components"
import { message, translate, useIntl } from "../../i18n/translate.helper"
import { getQrCodeImage } from "../../helpers/utils"
import { IOmiseConfig } from "../../hooks/useLink"
import { media } from "../../helpers/styles"
import Button from "../Build/Button"
import Action, { WrapperAction } from "./Action"
import { isMobile } from "react-device-detect"

type Props = {
  qrCodeImageUrl: string;
  omiseConfig: IOmiseConfig;
  onClickDownLoadQr: (qrCodeImageUrl: string) => void;
  onClickBack: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const QrCode = ({ qrCodeImageUrl, omiseConfig, onClickDownLoadQr, onClickBack } : Props) => {
  const intl = useIntl()
  const description = {
    desktop: [
      intl.formatMessage({ id: message.qr_code_desc_desktop_no_1 }),
      intl.formatMessage({ id: message.qr_code_desc_desktop_no_2 }),
      intl.formatMessage({ id: message.qr_code_desc_desktop_no_3 }),
      intl.formatMessage({ id: message.qr_code_desc_desktop_no_4 }),
    ],
    mobile: [
      intl.formatMessage({ id: message.qr_code_desc_mobile_no_1 }),
      intl.formatMessage({ id: message.qr_code_desc_mobile_no_2 }),
      intl.formatMessage({ id: message.qr_code_desc_mobile_no_3 }),
      intl.formatMessage({ id: message.qr_code_desc_mobile_no_4 }),
      intl.formatMessage({ id: message.qr_code_desc_mobile_no_5 }),
      intl.formatMessage({ id: message.qr_code_desc_mobile_no_6 }),
    ],
  }
  const descriptionMessages = isMobile ? description.mobile : description.desktop
  const pleaseNotes = [
    intl.formatMessage({ id: message.qr_code_please_notes_no_1 }),
    intl.formatMessage({ id: message.qr_code_please_notes_no_2 }),
  ]

  const handleClickBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClickBack(e)
  }

  return (
    <QrCodeWrapper>
      <QrCodeHeader>
        <QrCodeHeaderLogo>
          <MerchantLogo
            src={omiseConfig.image || 'https://cdn.omise.co/assets/frontend-images/store-logo.svg'}
            alt={omiseConfig.frameLabel}
          />
        </QrCodeHeaderLogo>
        <QrCodeHeaderDetail>
          <QrCodeHeaderDetailTitle>
            { omiseConfig.frameLabel }
          </QrCodeHeaderDetailTitle>
          <QrCodeHeaderDetailSubTitle>
            { omiseConfig.frameDescription }
          </QrCodeHeaderDetailSubTitle>
        </QrCodeHeaderDetail>
      </QrCodeHeader>
      <QrCodeBack>
        <QrCodeBackButton
          onClick={handleClickBack}
        >
          <svg width="16" height="16" fill="none">
            <path
              stroke={omiseConfig.style.submitButton.backgroundColor}
              strokeWidth="2"
              d="M8 14L2 8l6-6"
            />
            <path
              fill={omiseConfig.style.submitButton.backgroundColor}
              d="M2 7h13v2H2z"
            />
          </svg>
        </QrCodeBackButton>
      </QrCodeBack>
      <InstructionWrapper>
        <Note>
          { translate(message.please_follow_instructions_payment) }
        </Note>
        <QrCodeDescriptionList>
          { descriptionMessages.map((descMsg, index) => (
            <QrCodeDescriptionItem key={index}>
              { descMsg }
            </QrCodeDescriptionItem>
          )) }
        </QrCodeDescriptionList>
      </InstructionWrapper>
      <InstructionWrapper>
        <Note>
          { translate(message.qr_code_please_notes) }
        </Note>
        <QrCodeDescriptionList>
          { pleaseNotes.map((noteMsg, index) => (
            <QrCodeDescriptionItem key={index}>
              { noteMsg }
            </QrCodeDescriptionItem>
          )) }
        </QrCodeDescriptionList>
      </InstructionWrapper>
      <QrCodeImageWrapper>
        <QrCodeImage
          src={getQrCodeImage(qrCodeImageUrl)}
          alt="qr code"
      />
      </QrCodeImageWrapper>
      <Action>
        <Button
          outline={true}
          onClick={(e) => onClickDownLoadQr(
            getQrCodeImage(qrCodeImageUrl)
          )}
        >
          { translate(message.download_qr_code) }
        </Button>
      </Action>
    </QrCodeWrapper>
  )
}

export default QrCode

const QrCodeWrapper = styled.div`
  height: 574px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: auto;

  ${WrapperAction} {
    padding-bottom: 30px;
  }

  @media ${media.mobile} {
    background: #fff;
    box-shadow: 0 2px 10px rgb(4 7 13 / 10%);
    border-radius: 10px;
  }
`

const QrCodeHeader = styled.div`
  display: flex;
  padding: 24px 9px 14px 9px;
`

const MerchantLogo = styled.img``

const QrCodeHeaderLogo = styled.div`
  display: flex;
  width: 52px;
  height: 52px;
  margin-right: 15px;

  ${MerchantLogo} {
    width: 52px;
    height: 52px;
  }
`

const QrCodeHeaderDetail = styled.div`
  display: flex;
  flex-direction: column;
`

const QrCodeHeaderDetailTitle = styled.div`
  color: rgb(4, 7, 13);
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5em;
`

const QrCodeHeaderDetailSubTitle = styled.div`
  color: rgb(69, 76, 89);
  font-size: 14px;
  line-height: 1.4em;
  opacity: 0.5;
`

const QrCodeBack = styled.div`
  padding: 0 9px 0 9px;
`

const QrCodeBackButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 0;

  svg {
    position: relative;
    top: -1px;
  }

  span {
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
  }
`

const QrCodeImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: -15px;
  margin-left: -15px;
`

const QrCodeImage = styled.img`
  max-width: 146px;

  @media ${media.mobile} {
    max-width: 80%;
  }
`

const QrCodeDescriptionList = styled.ul`
  margin: 10px 0 25px 0;
  padding: 0 0 0 20px;
  list-style: decimal;
`

const QrCodeDescriptionItem = styled.li`
  margin-bottom: 8px;
  font-size: 12px;
  color: #3C414D;
`

const InstructionWrapper = styled.div`
  margin: 15px 10px 0 10px;
  font-size: 12px;
`

const Note = styled.p`
  margin: 0;
`
