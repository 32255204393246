/**
 * used by reducer and datadog
 */
export const SET_LOADING = 'SET_LOADING'
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_PAYMENT_INPUT = 'SET_PAYMENT_INPUT'
export const SEE_REFUND_POLICY = 'SEE_REFUND_POLICY'

/**
 * used by localstorage
 */
export const STORAGE_LANGUAGE = "lang"
export const STORAGE_CHARGE_ID = "charge_id"
