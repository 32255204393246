import styled from 'styled-components'

type Props = {
  backgroundColor: string;
}

const Merchant = styled.div.attrs((props: Props) => ({
  backgroundColor: props.backgroundColor || '#fff',
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 574px;
  background-color: ${(props) => props.backgroundColor};
  padding: 24px;
  box-sizing: border-box;
`

export default Merchant
