import { ICapability } from "../services/link.transform";
import { TInstallmentTerm } from "../hooks/useLink";

export const mapPaymentMethod2FriendlyText = (value: string) => {
  const methods: any = {
    internet_banking_bay: "Krungsri Online",
    internet_banking_bbl: "Bualuang iBanking",
    internet_banking_ktb: "KTB Netbank",
    internet_banking_scb: "SCB Easy Net",
    installment_bay: "Installment: Krungsri",
    installment_bbl: "Installment: Bangkok Bank",
    installment_first_choice: "Installment: Krungsri First Choice",
    installment_kbank: "Installment: Kasikorn Bank",
    installment_ktc: "Installment: Krungthai Card (KTC)",
    installment_scb: "Installment: Siam Commercial Bank",
    installment_citi: "Installment: Citi",
    installment_ttb: "Installment: TTB",
    installment_uob: "Installment: UOB",
    installment_ezypay: "Installment: EzyPay",
    truemoney: "TrueMoney Wallet",
    fpx: "FPX",
    promptpay: "PromptPay",
    points_citi: "Citi Pay with Points",
    paynow: "PayNow",
    touch_n_go: "Touch ’n Go",
    alipay: "Alipay online",
    maybank_qr: "Maybank QRPay",
    duitnow_qr: "DuitNow QR",
    duitnow_obw: "DuitNow Online Banking/Wallets",
    shopeepay: "ShopeePay",
    boost: "Boost",
    grabpay: "GrabPay",
    paypay: "PayPay",
    mobile_banking_kbank: "KBank (K PLUS)",
    mobile_banking_scb: "SCB (SCB Easy)",
    mobile_banking_bay: "Krungsri (KMA)",
    mobile_banking_bbl: "Bangkok Bank",
  };
  return methods[value] || value;
};

/**
 * Used to return payment method code that match for omise.js
 * @param value method code provided by omise apis: https://www.omise.co/capability-api
 * @returns payment code for omise.js: https://www.omise.co/omise-js
 */
export const getPaymentMethodCode = (value: string) => {
  const methods: any = {
    card: "credit_card",
    "debit/credit": "credit_card",
  };
  return methods[value] || value;
};

// same as above but return as string in array
export const getPaymentMethodCodes = (
  capabilities: ICapability[] = []
): string[] => {
  let paymentMethods: string[] = [];
  capabilities.forEach((item) => {
    const method = getPaymentMethodCode(item.paymentMethod);
    paymentMethods.push(method);
    if (method.startsWith('installment_')) {
      const bankName = method.split('_')[1];
      paymentMethods.push(`installment_wlb_${bankName}`);
    }
  });
  return paymentMethods;
};

export const getInstallmentTerms = (
  capabilities: ICapability[] = []
): TInstallmentTerm[] => {
  let terms: TInstallmentTerm[] = [];
  capabilities.forEach((item) => {
    const name = getPaymentMethodCode(item.paymentMethod);
    const term: TInstallmentTerm = {
      name: name,
      terms: Array.isArray(item.installmentTerms)
        ? item.installmentTerms.filter((item: any) => item > 0).map((item: any) => parseInt(item))
        : []
    };
    terms.push(term);
    if (name.startsWith('installment_')) {
      const bankName = name.split('_')[1];
      const newTerm: TInstallmentTerm = {
        name: `installment_wlb_${bankName}`,
        terms: term.terms
      };
      terms.push(newTerm);
    }
  });

  return terms;
};
