import styled from "styled-components"

export enum IconList {
  Check = 'check.svg',
  Subtract = 'subtract.svg',
  Line = 'line.svg',
  Facebook = 'facebook.svg',
  Instagram = 'instagram.svg',
  Loading = 'loading.svg',
  Error = 'error.svg',
  Omise = 'omise.svg',
  Opn = 'opn-payments.svg',
}

type Props = {
  src: IconList;
  className?: string;
  style?: React.CSSProperties;
}

const Icon = ({ src, ...rest }: Props) => {
  let iconSource
  try {
    iconSource = require(`../../assets/icons/${src}`)
  } catch {
    return null
  }
  return (
    <WrapperIcon {...rest}>
      <Img src={iconSource} />
    </WrapperIcon>
  )
}

export const WrapperIcon = styled.div``

const Img = styled.img`
  width: 100%;
  height: 100%;
`

export default Icon
