import dayjs from "dayjs"
import { defaultsValue } from "../helpers/utils"

export const transformLink = (data: any = {}) : IResponseApiLink => {
  return {
    id: data?.id,
    name: defaultsValue(data?.name) as string,
    merchant: transformMerchant(data),
    order: transformOrder(data),
    capabilities: transformCapabilities(data),
    lang: data?.company?.default_locale,
    zeroInterestinstallments: defaultsValue(data?.template?.zero_interest_installment, null) as boolean | null,
    saveCard: getSaveCardValue(data.capabilities)
  }
}

const getSaveCardValue = (capabilities: { payment_method: string, saveCard?: boolean }[] | undefined) => {
  const cardMethod = capabilities?.find(item => item.payment_method === 'card')

  if (cardMethod) return cardMethod.saveCard || false;
  return false;
}

export const transformMerchant = (data: any = {}) : IMerchant => {
  return {
    publicKey: defaultsValue(data?.company?.public_key) as string,
    logo: defaultsValue(data?.company?.logo) as string,
    name: defaultsValue(data?.company?.name) as string,
    description: (() => {
      const value = defaultsValue(data?.company?.description) as string;
      return value.toLowerCase() !== 'null'
        ? data?.company?.description
        : ''
    })(),
    theme: transformTheme(data),
    policy: transformPolicy(data),
    webhooks: data?.company?.webhooks,
  }
}

export const transformTheme = (data: any = {}) : TypeTheme => {
  return {
    backgroundColor: defaultsValue(data?.company?.background_color) as string,
    fontColor: defaultsValue(data?.company?.text_color) as string,
  }
}

export const transformPolicy = (data: any = {}) : TPolicy => {
  return {
    refund: defaultsValue(data?.company?.refund_policy) as string,
  }
}

export const transformOrder = (data: any = {}) : IOrder => {
  return {
    state: defaultsValue(data?.state) as string,
    amount: defaultsValue(data?.amount, 0) as number,
    currency: defaultsValue(data?.currency) as string,
    expiryDate: (() => {
      return data?.expired_at
        ? dayjs(data.expired_at).format('D MMM YYYY, HH:mm:ss')
        : ''
    })(),
    additional: defaultsValue(data?.additional) as string,
    features: defaultsValue(data?.features, {}) as object,
    meta: transformLinkFeatures(defaultsValue(data?.features, {}) as object),
  }
}

export const transformCapability = (item: any = {}) : ICapability => {
  return {
    paymentMethod: defaultsValue(item?.payment_method) as string,
    installmentTerms: defaultsValue(item?.installment_terms, []) as number[],
  }
}

export const transformCapabilities = (data: any = {}) : ICapability[] => {
  return data?.capabilities?.map((item: any) => transformCapability(item))
}

export const transformLinkFeatures = (data: any = {}) => {
  return Object.keys(data).reduce((acc: any, cur: string) => {
    const excludedKeys = [
      'platform',
      'link_id',
      'link_name',
      'transaction_id',
      'additional',
      'client_id',
    ]
    if(!excludedKeys.includes(cur)) {
      acc.push({ name: cur, value: data[cur] })
    }
    return acc
  }, [])
}

export interface IMerchant {
  publicKey: string;
  logo: string;
  name: string;
  description: string;
  theme: TypeTheme;
  policy: TPolicy;
  webhooks: string[];
}

export interface IOrder {
  state: string;
  amount: number;
  currency: string;
  expiryDate: string;
  additional: string;
  features: object;
  meta: {
    name: string;
    value: string;
  }[];
}

export interface ICapability {
  paymentMethod: string;
  installmentTerms: number[];
}

export type TypeTheme = {
  backgroundColor: string;
  fontColor: string;
}

export type TPolicy = {
  refund: string;
}

export interface IResponseApiLink {
  id: number;
  name: string;
  merchant: IMerchant;
  order: IOrder;
  capabilities: ICapability[];
  lang: string;
  zeroInterestinstallments: boolean | null;
  saveCard: boolean;
}

export interface IPaymentFormConfiguration {
  textForFooter:  string
}
