import { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import Icon, { IconList, WrapperIcon } from "./Icon"

export enum Types {
  Success,
  Error,
}

type Props = {
  type: Types;
  message: string;
  style?: React.CSSProperties;
}

const Alert = ({ type, message, ...rest }: Props) => {
  const [iconSrc, setIconSrc] = useState<IconList>(IconList.Check)
  useEffect(() => {
    if(type === Types.Success) {
      setIconSrc(IconList.Check)
    } else if(type === Types.Error) {
      setIconSrc(IconList.Error)
    }
  }, [type])
  return (
    <AlertWrapper { ...rest }>
      <Inner type={type}>
        <Icon
          src={iconSrc}
          style={{ width: '16px', height: '16px', }}
        />
        { message }
      </Inner>
    </AlertWrapper>
  )
}

export default Alert

type AlertWrapperProps = {
  type: Types;
}

const AlertWrapper = styled.div`
  display: flex;

  ${WrapperIcon} {
    margin-right: 8px;
  }
`

const theme = {
  success: css`
    background-color: #DCFAF4;
    color: #3C414D;
  `,
  error: css`
    background-color: #FFEFED;
    color: #3C414D;
  `,
}

const Inner = styled.div.attrs((props: AlertWrapperProps) => ({
  type: props.type || Types.Success
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 4px;
  ${(props) => {
    if(props.type === Types.Success) {
      return theme.success
    } else if(props.type === Types.Error) {
      return theme.error
    }
  }}
`
