import styled, { keyframes } from "styled-components";

const Loading = () => {
  return (
    <Wrapper>
      <Spinner style={{ width: '48px', height: '48px', }}>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M36 24C36 17.3726 30.6274 12 24 12C17.3726 12 12 17.3726 12 24C12 30.6274 17.3726 36 24 36C30.6274 36 36 30.6274 36 24ZM24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4Z" fill="#D4E3F9"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M36 24C36 17.3726 30.6274 12 24 12C17.3726 12 12 17.3726 12 24C12 26.2091 10.2091 28 8 28C5.79086 28 4 26.2091 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 26.2091 42.2091 28 40 28C37.7909 28 36 26.2091 36 24Z" fill="url(#paint0_linear_501:24)"/>
          <defs>
          <linearGradient id="paint0_linear_501:24" x1="40" y1="24.1006" x2="8" y2="24.1006" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1A56F0"/>
            <stop offset="1" stopColor="#D4E3F9" stopOpacity="0"/>
          </linearGradient>
          </defs>
        </svg>
      </Spinner>
    </Wrapper>
  )
}

export default Loading

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, .5);
`

const Spinner = styled.div`
  animation: ${spin} 1500ms linear infinite;
`
