import numeral from "numeral";

export const loadScript = (url: any) => {
  return new Promise((resolve, reject) => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const getPayAmount = (value: number, currency: string) => {
  if (currency === "JPY") {
    return numeral(value).format("0,");
  }
  return numeral(value / 100).format("0,0.00");
};

export const getPspFromURL = () => {
  const regex = /([^\.]+)\.linksplus/;
  const match = window.location.hostname.match(regex);

  return match && match[1] ? match[1] : null;
};

export const transformConfig = (config: any) => {
  if (!config?.image) {
    delete config.image;
  }
  if (getPspFromURL()) {
    config.style.securedBySection = {
      position: "fixed",
      left: "-600px",
      bottom: "26px",
    };
  }
  return config;
};
