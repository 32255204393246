import { message } from "../translate.helper";

const ja = {
  [message.charge_id]: "課金ID",
  [message.purchase_details]: "購入内容",
  [message.see_refund_policy]: "返金に関するポリシーは",
  [message.here]: "こちら",
  [message.pay_before]: "Pay before",
  [message.payment_successful]: "支払い完了",
  [message.payment_date]: "支払期日",
  [message.payment_method]: "支払方法",
  [message.back_to_merchant]: "元のページに戻る",
  [message.save_to_files]: "ファイルに保存",
  [message.share_to_email]: "メールで共有",
  [message.mail_receipt]: "領収書を送信する",
  [message.enter_email_send_receipt]:
    "領収書を送信するメールアドレスを入力してください",
  [message.email]: "Eメール",
  [message.cancel]: "キャンセル",
  [message.send]: "送信する",
  [message.receipt_sent]: "領収書が送信されました",
  [message.oops_link_is_invalid]: "Oops, link is invalid!",
  [message.link_has_been_used_or_doesnt_exist]:
    "The link you’re looking for has been used or doesn’t exist",
  [message.unable_to_process_the_payment]: "Unable to process the payment",
  [message.unable_to_send_email]: "Unable to send email at the moment",
  [message.done]: "Done",
  [message.payment_has_been_finished]:
    "Your payment process has been finished, you can close the paylink now.",
  [message.please_follow_instructions_payment]:
    "Please follow the instructions below to make payment",
  [message.qr_code_desc_desktop_no_1]: "Launch your preferred bank app",
  [message.qr_code_desc_desktop_no_2]: "Scan the QR code",
  [message.qr_code_desc_desktop_no_3]: "Verify amount and authorise payment",
  [message.qr_code_desc_desktop_no_4]:
    "Page will refresh upon successful receipt of payment",
  [message.qr_code_desc_mobile_no_1]:
    "Screen shot / save the QR code to your photo gallery",
  [message.qr_code_desc_mobile_no_2]:
    'Open your preferred bank app, go to "Scan QR"',
  [message.qr_code_desc_mobile_no_3]:
    "Upload / import the QR code from album / photo gallery",
  [message.qr_code_desc_mobile_no_4]: "Verify amount and authorise payment",
  [message.qr_code_desc_mobile_no_5]:
    "Return to your checkout page on the browser / app",
  [message.qr_code_desc_mobile_no_6]:
    "Page will refresh upon successful receipt of payment",
  [message.qr_code_please_notes]: "Please note:",
  [message.qr_code_please_notes_no_1]:
    "You will be paying to Omise Payment Singapore Pte. Ltd, authorised payment processor on our behalf",
  [message.qr_code_please_notes_no_2]:
    "One QR is for one order only, please do not make multiple payments with the same QR",
  [message.download_qr_code]: "Download QR",
  [message.payment_failed]: "Payment Failed",
};

export default ja;
