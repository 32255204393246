import axios, { AxiosInstance } from 'axios'

import { getPspFromURL } from "../plugins/omisejs-wrapped/helpers/utils";

export const apiUrl = `${process.env.REACT_APP_LINK_PLUS_API_URL}/`

const instance : AxiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 1000 * 30,
  headers: {
    'Subdomain': getPspFromURL(),
  },
})

export default instance
