import styled from 'styled-components'
import { message, translate } from '../../../i18n/translate.helper'
import { format } from "date-fns-tz";
import { ja } from "date-fns/locale";
import { useIntl } from "react-intl";

const MerchantFooter = ({ expireDate, fontColor }: Props) => {
  const intl = useIntl()
  return expireDate ?
    <MerchantFooterWrapper fontColor={fontColor}>
        <MerchantFooterLine/>
        <Label>
          <Icon>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none">
              <circle cx="8.17181" cy="9.28021" r="6.67181" stroke={fontColor}/>
              <path d="M4.58155 1L1.27148 4.31007" stroke={fontColor}/>
              <path d="M4.85816 15.3456L3.20312 17.0006" stroke={fontColor}/>
              <path d="M12.0356 1L15.3457 4.31007" stroke={fontColor}/>
              <path d="M11.4836 15.3456L13.1387 17.0006" stroke={fontColor}/>
              <path d="M8 5V9H12" stroke={fontColor}/>
            </svg>
          </Icon>
          <Text>
            { translate(message.pay_before) }
          </Text>
        </Label>
      <DateTime>
        {intl.locale === "ja"
          ? format(new Date(expireDate), "PPP", {
              locale: ja,
            })
          : expireDate}
      </DateTime>
    </MerchantFooterWrapper> : null
}

export default MerchantFooter

type Props = {
  expireDate: string;
  fontColor: string;
}

type WrapperProps = {
  fontColor: string;
}

export const MerchantFooterWrapper = styled.div.attrs((props: WrapperProps) => ({
  fontColor: props.fontColor || '#000',
}))`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: ${(props) => props.fontColor};
  font-size: 12px;
`

export const MerchantFooterLine = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 48px);
  height: 1px;
  background-color: rgba(60, 65, 77, 0.2);
`

const Label = styled.div``

const Icon = styled.span`
  position: relative;
  top: 4px;
`

const Text = styled.span`
  margin-left: 12px;
  opacity: 0.7;
`

const DateTime = styled.span``
