import styled from "styled-components"

type Props = {
  level?: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const getHeading = (level: number) : any => {
  switch(level) {
    case 6:
      return 'h6'
    case 5:
      return 'h5'
    case 4:
      return 'h4'
    case 3:
      return 'h3'
    case 2:
      return 'h2'
    default:
      return 'h1'
  }
}

const Heading = ({ level, children, ...rest }: Props) => {
  const HeadingTag = getHeading(level || 1)
  return (
    <Wrapper>
      <HeadingTag { ...rest }>
        { children }
      </HeadingTag>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-weight: 900;
  color: #04070D;

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px 0;
  }
`

export default Heading
