import { datadogRum } from "@datadog/browser-rum"
import {
  SET_LOADING,
  SWITCH_LANGUAGE,
  SET_PROGRESS,
  STORAGE_LANGUAGE,
  SET_PAYMENT_INPUT,
} from "../helpers/constants"
import { InitialState } from "./"
import { PaymentInput, Progress } from "../helpers/enums"
import { getLang } from "../helpers/locale"
import { setItem } from "../helpers/storage"

 export const statePropNames = Object.freeze({
  isLoading: 'isLoading',
  lang: 'lang',
  progress: 'progress',
  paymentInput: 'paymentInput',
})

type Action = {
  type: string;
  payload: any;
}

export const reducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        [statePropNames.isLoading]: action.payload,
      }
    case SWITCH_LANGUAGE:
      // store selected language on client's web browser
      setItem(STORAGE_LANGUAGE, action.payload)
      // log action in Datadog
      datadogRum.addAction(SWITCH_LANGUAGE, {
        code: action.payload,
      })
      return {
        ...state,
        [statePropNames.lang]: action.payload,
      }
    case SET_PROGRESS:
        return {
          ...state,
          [statePropNames.progress]: action.payload,
        }
    case SET_PAYMENT_INPUT:
        return {
          ...state,
          [statePropNames.paymentInput]: action.payload,
        }
    default:
      return state
  }
}

// @ts-ignore: set properties by using a string value that is stored in `statePropNames`
export const initialState: InitialState = {
  [statePropNames.isLoading]: true,
  [statePropNames.lang]: getLang(),
  [statePropNames.progress]: Progress.Checkout,
  [statePropNames.paymentInput]: PaymentInput.FillForm,
}
