import { useContext, useState } from "react";
import { AxiosError } from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { AppContext } from "../../contexts";
import { IOmiseConfig } from "../../hooks/useLink";
import { message, useIntl } from "../../i18n/translate.helper";
import { isMobile, isTablet } from "../../helpers/deviceDetector";
import { saveAsImage, saveAsPdf } from "../../helpers/fileDownloader";
import { SEE_REFUND_POLICY } from "../../helpers/constants";
import { ErrorResponse, PaymentInput, Progress } from "../../helpers/enums";
import { openUrl, popupWindow } from "../../helpers/popupWindow";
import {
  IResponseApiPostCharge,
  IResponseApiGetCharge,
} from "../../services/charges.transform";
import Alert, { Types as AlertTypes } from "../Build/Alert";
import ContainerPaid from "../Build/ContainerPaid";
import Payment from "../Combine/Payment";
import FormMail, { FormProps } from "../Combine/FormMail";
import Footer from "../Combine/Footer";
import Layout from "../Combine/Layout";
import Receipt from "../Combine/Receipt";
import QrCode from "../Combine/QrCode";
import Callback from "../Combine/Callback";
import { getPspFromURL } from "../../plugins/omisejs-wrapped/helpers/utils";
import { isExternalSecuredBy } from "../../helpers/utils"
import { OmiseCallbackPayload } from "../../plugins/omisejs-wrapped/components/App";
import { IPaymentFormConfiguration } from "../../services/link.transform";

type Props = {
  omiseConfig: IOmiseConfig;
  responseApiPostCharge: IResponseApiPostCharge;
  responseApiGetCharge: IResponseApiGetCharge;
  error: ErrorResponse;
  onReady: () => void;
  onSendReceiptEmail: (
    email: string,
    callback: (error: AxiosError) => void
  ) => void;
  onCancelSendReceiptEmail: () => void;
  onClickBackQrCode: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onSubmit?: (payload: OmiseCallbackPayload) => void;
  onClickBackToMerchantPage:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | null;
  paymentFormConfiguration: IPaymentFormConfiguration;
};

enum Display {
  ReceiptDetail,
  SendEmailForm,
}

const App = (props: Props) => {
  const {
    omiseConfig,
    responseApiPostCharge,
    responseApiGetCharge,
    error,
    onReady,
    onSendReceiptEmail,
    onCancelSendReceiptEmail,
    onClickBackQrCode,
    onSubmit,
    onClickBackToMerchantPage,
    paymentFormConfiguration,
  } = props;
  const intl = useIntl();
  const { state } = useContext(AppContext);
  const [capturingScreen, setCapturingScreen] = useState<boolean>(false);
  const [display, setDisplay] = useState<Display>(Display.ReceiptDetail);
  const [isSubmittedEmailForm, setIsSubmittedEmailForm] =
    useState<boolean>(false);
  const [isSentReceipt, setIsSentReceipt] = useState<boolean>(false);

  const handleClickSaveFile = (element: HTMLDivElement) => {
    setCapturingScreen(true);
    setTimeout(() => {
      const saveAs = (() => {
        if (isMobile() || isTablet()) {
          return saveAsImage;
        }
        return saveAsPdf;
      })();
      saveAs(element).then(([err, fileName]: any) => {
        setCapturingScreen(false);
      });
    }, 500);
  };

  const handleClickShareEmail = () => {
    setDisplay(Display.SendEmailForm);
    setIsSentReceipt(false);
  };

  const handleCancelSendEmail = () => {
    setDisplay(Display.ReceiptDetail);
    onCancelSendReceiptEmail();
  };

  const callbackSentEmailSuccess = (err: AxiosError) => {
    if (err) {
      setIsSubmittedEmailForm(false);
      setIsSentReceipt(false);
    } else {
      setIsSubmittedEmailForm(false);
      setIsSentReceipt(true);
      setDisplay(Display.ReceiptDetail);
    }
  };

  const handleSubmitSendEmail = (
    e: React.FormEvent<HTMLFormElement>,
    form: FormProps
  ) => {
    setIsSubmittedEmailForm(true);
    onSendReceiptEmail(form.email, callbackSentEmailSuccess);
  };

  const handleOnClickRefundPolicy = () => {
    const url = "";
    const windowName = "RefundPolicy";
    const options = {
      width: window.innerWidth / 1.2,
      height: window.innerHeight / 1.5,
    };
    const tabTitle = `${omiseConfig.frameLabel} - Refund Policy`;
    const refundPolicyPdfUri = omiseConfig.refundPolicy;
    const refundPolicyWindow = popupWindow(
      url,
      windowName,
      window,
      options
    ) as Window;
    refundPolicyWindow.document.write(`
      <title>${tabTitle}</title>
      <style>
        body {
          margin: 0;
        }
      </style>
      <body>
        <iframe src="${refundPolicyPdfUri}" width="100%" height="${options.height}" />
      </body>
    `);
    // log action in Datadog
    datadogRum.addAction(SEE_REFUND_POLICY, {
      merchant_name: omiseConfig.frameLabel,
    });
  };

  const handleClickDownloadQrCode = (imageUrl: string) => {
    openUrl(imageUrl);
  };

  const handleClickBackQrCode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickBackQrCode(e);
  };

  return (
    <Layout isLoading={state.isLoading}>
      {state.progress === Progress.Checkout && (
        <>
          {error === ErrorResponse.PaymentFail && (
            <Alert
              type={AlertTypes.Error}
              message={intl.formatMessage({
                id: message.unable_to_process_the_payment,
              })}
              style={{ justifyContent: "center", marginBottom: "10px" }}
            />
          )}
          <Payment
            omiseConfig={omiseConfig}
            onReady={onReady}
            onClickRefundPolicy={handleOnClickRefundPolicy}
            customForm={
              state.paymentInput === PaymentInput.ScanQrCode ? (
                <QrCode
                  qrCodeImageUrl={responseApiPostCharge.qrCodeUri}
                  omiseConfig={omiseConfig}
                  onClickDownLoadQr={handleClickDownloadQrCode}
                  onClickBack={handleClickBackQrCode}
                />
              ) : null
            }
            error={error}
            onSubmit={onSubmit}
          />
        </>
      )}
      {state.progress === Progress.Paid && (
        <ContainerPaid>
          {display === Display.ReceiptDetail && (
            <>
              {isSentReceipt && (
                <Alert
                  type={AlertTypes.Success}
                  message={intl.formatMessage({ id: message.receipt_sent })}
                  style={{ justifyContent: "center", marginBottom: "10px" }}
                />
              )}
              <Receipt
                capturingScreen={capturingScreen}
                responseApiGetCharge={responseApiGetCharge}
                onClickSaveFile={handleClickSaveFile}
                onClickShareEmail={handleClickShareEmail}
                onClickBackToMerchantPage={onClickBackToMerchantPage}
              />
            </>
          )}
          {display === Display.SendEmailForm && (
            <>
              {error === ErrorResponse.SentEmailFail && (
                <Alert
                  type={AlertTypes.Error}
                  message={intl.formatMessage({
                    id: message.unable_to_send_email,
                  })}
                  style={{
                    justifyContent: "center",
                    marginBottom: "10px",
                    margin: "0 0 30px 0",
                  }}
                />
              )}
              <FormMail
                isSubmitted={isSubmittedEmailForm}
                onClickCancel={handleCancelSendEmail}
                onSubmit={handleSubmitSendEmail}
              />
            </>
          )}
        </ContainerPaid>
      )}
      {state.progress === Progress.CallbackAuth && (
        <ContainerPaid>
          <Callback
            title={intl.formatMessage({ id: message.done })}
            description={intl.formatMessage({
              id: message.payment_has_been_finished,
            })}
            onClickBackToMerchantPage={onClickBackToMerchantPage}
          />
        </ContainerPaid>
      )}
      {state.progress === Progress.PaymentFail && (
        <ContainerPaid>
          <Callback
            title={intl.formatMessage({ id: message.payment_failed })}
            description={responseApiGetCharge.failureMessage}
            onClickBackToMerchantPage={onClickBackToMerchantPage}
          />
        </ContainerPaid>
      )}
      <Footer
        showSecuredBy={
          [Progress.Paid, Progress.CallbackAuth].includes(state.progress) &&
          (getPspFromURL() ? false : true)
        }
        showExternalSecuredBy={isExternalSecuredBy(paymentFormConfiguration)}
        paymentFormConfiguration={paymentFormConfiguration}
      />
    </Layout>
  );
};

export default App;
