import React from 'react'
import ReactDOM from 'react-dom'
import { init as initDatadogRum } from './plugins/datadog'
import { I18nProvider } from './i18n'
import { AppProvider } from './contexts'
import reportWebVitals from './reportWebVitals'
import App from './components/Integrate/App'
import './assets/styles/global.css'

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <I18nProvider>
        <App/>
      </I18nProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// plugins
initDatadogRum()
