import { STORAGE_LANGUAGE } from "./constants"
import { getItem } from "./storage"
import { Lang } from "./enums"

export const getLang = () => {
  const lang = getItem(STORAGE_LANGUAGE)
  const defaults = Lang.English
  if(!lang) {
    return defaults
  }
  return lang
}
