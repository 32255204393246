import styled from 'styled-components';

const Payjs = styled.div`
  position: absolute;
  background: none;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  transition: none;
`

export default Payjs
