import styled from "styled-components";
import { WrapperIcon } from "./Icon";

type Props = {
  outline?: boolean;
}

const Button = styled.button.attrs((props: Props) => ({
  outline: props.outline || false,
}))`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  min-width: 120px;
  background: ${(props: Props) => props.outline ? 'none' : '#000'};
  font-weight: bold;
  font-size: 14px;
  color: ${(props: Props) => props.outline ? '#3C414D' : '#fff'};
  border: ${(props: Props) => props.outline ? 'solid 1px #e4e7ed' : 'none'};
  outline: none;
  border-radius: 4px;
  cursor: pointer;

  ${WrapperIcon} {
    &.loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export default Button
